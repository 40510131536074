<template>
  <main class="main">
    <div class="bg1">
      <img v-lazy="require('./fwyd_img/banner.webp')" alt="" class="banner" />
      <span class="fwyd">服务诱导</span>
      <span class="fh1"
        >一体化的服务流程，实现平台路由线上化。加强服务质量，增强服务效果
      </span>
    </div>
    <div class="bg2">
      <span class="hd">活动</span>
      <span class="fh2">通过活动来提高游客的参与性，与游玩的兴致</span>
      <div class="box1">
        <img src="./fwyd_img/icon1.png" alt="" class="icon1" />
        <!-- <img v-lazy="require('./fwyd_img/icon1.png')" /> -->
        <span class="yhj">
          优惠券
        </span>
        <span class="fh3"
          >线上通知，提前预售优惠券。不同的折扣优惠不同。每档的活动也不同</span
        >
      </div>
      <div class="box2">
        <img src="./fwyd_img/icon2.png" alt="" class="icon2" />
        <span class="mfcc">
          免费乘车
        </span>
        <span class="fh4">提前预定游玩路线。乘车卷要在线上提前预定</span>
      </div>
      <div class="box3">
        <img src="./fwyd_img/icon3.png" alt="" class="icon3" />
        <span class="tbyx">
          探宝游戏
        </span>
        <span class="fh5"
          >开设的新型项目，来探索不同的宝藏。试试各自的运气。提前在线上预定票</span
        >
      </div>
    </div>
    <div class="bg3">
      <img v-lazy="require('./fwyd_img/bg3.webp')" alt="" class="bg33" />
      <span class="jqts">景区特色</span>
      <span class="fh6">打造具有本景点的特色旅游景点</span>
      <img src="./fwyd_img/icon4.png" alt="" class="icon4" />
      <img src="./fwyd_img/icon5.png" alt="" class="icon5" />
      <img src="./fwyd_img/icon6.png" alt="" class="icon6" />
      <span class="lyjd">旅游景点</span>
      <span class="tsxm">特色项目</span>
      <span class="whdkd">网红打卡点</span>
    </div>
    <div class="div bg4">
      <span class="xspt">线上平台</span>
      <span class="fh8">支持订购的线上平台</span>
      <div class="img44">
        <img v-lazy="require('./fwyd_img/bg4.webp')" class="image4" />
      </div>
    </div>
    <Header></Header>
    <Footer></Footer>
  </main>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.bg1 {
  height: 593px;
  // background-image: url(./fwyd_img/banner.png);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .banner {
    height: 593px;
  }
  .fwyd {
    min-width: 309px;
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 230px;
    left: 361px;
    text-align: left;
  }
  .fh1 {
    min-width: 675px;
    height: 99px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg2 {
  height: 661px;
  position: relative;
  .hd {
    min-width: 83px;
    height: 56px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 918px;
  }
  .fh2 {
    min-width: 420px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 749px;
  }
  .box1 {
    min-width: 380px;
    height: 301px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 260px;
    left: 360px;
    .icon1 {
      min-width: 100px;
      height: 100px;
      position: absolute;
      top: 30px;
      left: 136px;
    }
    .yhj {
      min-width: 72px;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 146px;
      left: 156px;
    }
    .fh3 {
      width: 284px;
      height: 44px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 203px;
      left: 48px;
      text-align: center;
    }
  }
  .box2 {
    min-width: 380px;
    height: 301px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 260px;
    left: 770px;
    .icon2 {
      min-width: 100px;
      height: 100px;
      position: absolute;
      top: 30px;
      left: 136px;
    }
    .mfcc {
      min-width: 96px;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 146px;
      left: 142px;
    }
    .fh4 {
      width: 284px;
      height: 44px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 203px;
      left: 48px;
      text-align: center;
    }
  }
  .box3 {
    min-width: 380px;
    height: 301px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 260px;
    left: 1180px;
    .icon3 {
      min-width: 100px;
      height: 100px;
      position: absolute;
      top: 30px;
      left: 136px;
    }
    .tbyx {
      min-width: 96px;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 146px;
      left: 142px;
    }
    .fh5 {
      width: 284px;
      height: 44px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 203px;
      left: 48px;
      text-align: center;
    }
  }
}
.bg3 {
  height: 712px;
  position: relative;
  // background-image: url(./fwyd_img/bg3.png);
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 712px;
    width: 100%;
  }
  .bg33 {
    height: 712px;
  }
  .jqts {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 240px;
    left: 877px;
  }
  .fh6 {
    min-width: 294px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 320px;
    left: 812px;
  }
  .icon4 {
    min-width: 56px;
    height: 56px;
    position: absolute;
    top: 404px;
    left: 550px;
  }
  .icon5 {
    min-width: 56px;
    height: 56px;
    position: absolute;
    top: 404px;
    left: 878px;
  }
  .icon6 {
    min-width: 56px;
    height: 56px;
    position: absolute;
    top: 404px;
    left: 1206px;
  }
  .lyjd {
    min-width: 84px;
    height: 28px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 418px;
    left: 630px;
  }
  .tsxm {
    min-width: 84px;
    height: 28px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 418px;
    left: 958px;
  }
  .whdkd {
    min-width: 105px;
    height: 28px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 418px;
    left: 1286px;
  }
}
.bg4 {
  height: 720px;
  position: relative;
  .xspt {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 876px;
  }
  .fh8 {
    min-width: 189px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 864px;
  }
  .img44 {
    height: 434px;
    width: 1200px;
    position: absolute;
    top: 230px;
    left: 359px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 434px;
      width: 100%;
    }
    .image4 {
      width: 1200px;
    }
  }
}
</style>
